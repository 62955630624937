import AdminRoute from "../modual/admin/AdminRoute";
const Auths = [
  {
    key: 1,
    role: "ADMIN",
    access: [1, 2, 3, 4],
    filePath: <AdminRoute />,
  },
];
export default Auths;
