import * as React from "react";
import Paper from "@mui/material/Paper";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import { ToastContainer } from "react-toastify";
function DashboardContent() {
  return (
    <>
      <ToastContainer />
      <Outlet />

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <Footer sx={{ pt: 4 }} />
      </Paper>

    </>
  );
}

export default function MainPage() {
  return <DashboardContent />;
}
