import { DASHBOARD_IN_BOUND, DASHBOARD_OUT_BOUND, DASHBOARD_TICKETS, SET_USERLOGIN_DATA } from "./actionType";


export const setUserLoginData = (data) => {
  return {
    type: SET_USERLOGIN_DATA,
    payload: data,
  };
};
export const setDashboardTicketsCount = (data) => {
  return {
    type: DASHBOARD_TICKETS,
    payload: data,
  };
};
export const setDashboardInBoundCallCount = (data) => {
  return {
    type: DASHBOARD_IN_BOUND,
    payload: data,
  };
};
export const setDashboardOutBoundCallCount = (data) => {
  return {
    type: DASHBOARD_OUT_BOUND,
    payload: data,
  };
};
