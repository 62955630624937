import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import MuiAppBar from "@mui/material/AppBar";
import { NavBarListItem } from "./NavBarListItem";
import LogoutIcon from "@mui/icons-material/Logout";
import { LogoutFunctionHandler } from "../Api/APICall";
import { useDispatch, useSelector } from "react-redux";
import { blueGrey, red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// const userrole = "AGENT";
// const drawerWidth = userrole === "AGENT" ? 0 : 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, userrole }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: userrole === "AGENT" ? 0 : 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
function MyAppDrawer({ open, toggleDrawer, menuList, userrole }) {
  const navigate = useNavigate();

  return (
    <Drawer variant="permanent" open={open} userrole={userrole}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <img
          src={require("../../assets/logo.png")}
          alt="logo"
          style={{ width: "80%", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <NavBarListItem menuList={menuList} />
      </List>
    </Drawer>
  );
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, userrole }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: userrole === "AGENT" ? 0 : 240,
    width: `calc(100% - ${userrole === "AGENT" ? 0 : 240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
function MyAppTopBar({ open, toggleDrawer, dispatch, userrole }) {
  const { data } = useSelector((state) => state.UserDataReducer);
  const { userName } = data;
  const navigate = useNavigate();

  return (
    <AppBar
      style={{ backgroundColor: blueGrey[700] }}
      position="absolute"
      open={open}
      userrole={userrole}
    >
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => navigate("/")}
        >
          {process.env.REACT_APP_PROJECT_NAME}
        </Typography>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{
            mr: 1,
            backgroundColor: red[600],
            p: 2,
            fontWeight: "bold",
          }}
        >
          Welcome : <b>{userName}</b>
        </Typography>

        {/* <IconButton color="inherit" sx={{ mr: 2 }} title="Notifications">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        <IconButton
          color="inherit"
          sx={{ mr: 1 }}
          title="Logout"
          onClick={() => LogoutFunctionHandler(dispatch, data)}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default function NavBar({ menuList }) {
  const { userRole } = useSelector((state) => state.UserDataReducer.data);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <CssBaseline />
      <MyAppTopBar
        open={open}
        toggleDrawer={toggleDrawer}
        dispatch={dispatch}
        userrole={userRole.toUpperCase()}
      />
      <MyAppDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        menuList={menuList}
        userrole={userRole.toUpperCase()}
      />
    </>
  );
}
