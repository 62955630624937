import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/Drawer/index";
import Toolbar from "@mui/material/Toolbar";
import AdminMenuList from "../../components/menu-list/AdminMenuList";

export default function Admin() {
  return (
    <Box sx={{ display: "flex" }}>
      <NavBar menuList={AdminMenuList} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <div
          style={{
            marginTop: "1%",
            marginBottom: "4%",
            marginLeft: "1%",
            padding: "5px",
          }}
        >
          <Outlet />
        </div>
      </Box>
    </Box>
  );
}
