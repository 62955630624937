const userList = [
    {
        "id": 1,
        "userName": "Developer",
        "LoginId": "1234567890",
        "password": "password",
        "userRole": "admin"
    }, {
        "id": 2,
        "userName": "Arun Kumar",
        "LoginId": "9035751499",
        "password": "9035751499",
        "userRole": "admin"
    }, {
        "id": 3,
        "userName": "Gautham",
        "LoginId": "8825593780",
        "password": "8825593780",
        "userRole": "admin"
    }, {
        "id": 4,
        "userName": "Gajendra ",
        "LoginId": "8118801686",
        "password": "8118801686",
        "userRole": "admin"
    }, {
        "id": 5,
        "userName": "Mahendrajit SIngh",
        "LoginId": "7618760820",
        "password": "7618760820",
        "userRole": "admin"
    }, {
        "id": 6,
        "userName": "Shiv",
        "LoginId": "8277013227",
        "password": "8277013227",
        "userRole": "admin"
    }
]

export default userList;