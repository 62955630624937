import React, { useEffect, useState } from 'react'
import BarGraph from '../../../components/graphs/BarGraph'
import { GetSatartAndEndDate, GraphApiCall } from '../../CommanApiFunction';


export default function DashboardLineGraph() {
    const [BarGraphViewData, setBarGraphViewData] = useState({ lable: [], data: [] })
    const onChangeFilterHandler = (type) => {
        const { startDate, endDate } = GetSatartAndEndDate(type);
        GraphApiCall(`/bar?startDate=${startDate}&endDate=${endDate}`, setBarGraphViewData, "bar")
    }
    useEffect(() => {
        onChangeFilterHandler("Today");
    }, [])
    return (<>
        <BarGraph
            barLabels={BarGraphViewData.lable}
            data={BarGraphViewData.data}
            title={"BOT Response Count"}
            id={"callReport"}
            onChangeFilterHandler={onChangeFilterHandler}
        />
    </>
    )
}
