import dayjs from "dayjs";
import { decriptApiKeys } from "../../components/Api/APICall";

const initialState = {
  data: {},
  isLogin: false,
};

const UserDataReducer = (state = initialState, { type, payload }) => {
  const locallData = localStorage.getItem("isLogin");
  if (locallData) {
    const decodeData = decriptApiKeys(locallData);
    if (decodeData.status) {
      let decode = JSON.parse(decodeData.data);
      if (dayjs(decode.expiryDate).valueOf() > dayjs().valueOf()) {
        state.data = decode;
      } else {
        localStorage.removeItem("isLogin");
      }
    }
  }
  state.isLogin = locallData ? true : false;
  switch (type) {
    case "SET_USERLOGIN_DATA":
      return { ...state, data: payload };
    default:
      return state;
  }
};

export default UserDataReducer;
