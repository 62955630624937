const initialState = {
  Tickets: {
    HindiCancelledTickets: 0,
    EnglishCancelledTickets: 0,
    BOTResponded: 0,
    BOTNotResponded: 0,
    TotalCancelledTickets: 0,
    TotalAPICalls: 0,
    APIResultsSuccess: 0,
    APIResultsFail: 0,
    TotalBOTSuccessPercent: 0,
    TotalAPISuccessPercent: 0,
  },
  Inbound: {
    Total: 0,
    Answered: 0,
    NotAnswered: 0,
  },
  OutBound: {
    Total: 0,
    Answered: 0,
    NotAnswered: 0,
  },
};
const DashboardPerformanceReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "DASHBOARD_TICKETS":
      return { ...state, Tickets: payload };
    case "DASHBOARD_IN_BOUND":
      return { ...state, Inbound: payload };
    case "DASHBOARD_OUT_BOUND":
      return { ...state, OutBound: payload };
    default:
      return state;
  }
};

export default DashboardPerformanceReducer;
