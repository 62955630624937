import { Speed } from "@mui/icons-material";
import { Divider, Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardCard from '../../components/card/DashboardCard';
import { DashboardApiCall, GetSatartAndEndDate } from "../CommanApiFunction";
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ExplicitIcon from '@mui/icons-material/Explicit';
import LanguageIcon from '@mui/icons-material/Language';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ApiIcon from '@mui/icons-material/Api';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DashboardBarGraph from "./pages/DashboardBarGraph";
import DashboardLineGraph from "./pages/DashboardLineGraph";

const stylesIcon = { color: "#fff", fontSize: "4rem" };
export default function DashboardAdmin() {
  const { Tickets } = useSelector((state) => state.DashboardPerformanceReducer)
  const dispatch = useDispatch()
  useEffect(() => {
    const { startDate, endDate } = GetSatartAndEndDate("Today");
    DashboardApiCall(`?startDate=${startDate}&endDate=${endDate}`, dispatch)
  }, [])

  const TicketData = [
    {
      label: "Total Refund Query",
      value: Tickets.TotalCancelledTickets,
      icon: <InfoIcon style={{ stylesIcon }} />,
    },
    {
      label: "BOT Not Responded Query",
      value: Tickets.BOTNotResponded,
      icon: <NewReleasesIcon style={{ stylesIcon }} />,
    }, {
      label: "BOT Responded Query",
      value: Tickets.BOTResponded,
      icon: <QueryStatsIcon style={{ stylesIcon }} />,
    },
    {
      label: "English  Query",
      value: Tickets.EnglishCancelledTickets,
      icon: <ExplicitIcon style={{ stylesIcon }} />,
    },
    {
      label: "Hindi Query",
      value: Tickets.HindiCancelledTickets,
      icon: <LanguageIcon style={{ stylesIcon }} />,
    }, {
      label: "BOT Success Rate(%)",
      value: `${Tickets.TotalBOTSuccessPercent}%`,
      icon: <ThumbUpIcon style={{ stylesIcon }} />,
    }, {
      label: "API Success Rate(%)",
      value: `${Tickets.TotalAPISuccessPercent}%`,
      icon: <ApiIcon style={{ stylesIcon }} />,
    }, {
      label: "API Fail Count",
      value: Tickets.APIResultsFail,
      icon: <SmsFailedIcon style={{ stylesIcon }} />,
    }, {
      label: "API Success Count",
      value: Tickets.APIResultsSuccess,
      icon: <Speed style={{ stylesIcon }} />,
    }, {
      label: "Total API Calls",
      value: Tickets.TotalAPICalls,
      icon: <ContactPhoneIcon style={{ stylesIcon }} />,
    },

  ]

  return (
    <>
      <DashboardCard title={`Refund Query Counts`} dataList={TicketData} key={`Ticket_count`} />
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <Paper sx={{ p: 2 }}>
            <DashboardLineGraph />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Paper sx={{ p: 2 }}>
            <DashboardBarGraph />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
