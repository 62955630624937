import React from "react";
import "./404error.css";
import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <section className="page_404">
      <Container style={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div className="four_zero_four_bg">
                <Typography variant="h1" align={"center"}>
                  404
                </Typography>
              </div>
              <div className="contant_box_404">
                <Typography variant="h3" align={"center"}>
                  Look like you're lost
                </Typography>
                <Typography variant="h6" align={"center"}>
                  the page you are looking for not avaible!
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to={"/"} className="link_404">
                  Go to Home
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
