import { Card, Divider, Grid, MenuItem, Paper, Select, Typography } from "@mui/material";
import { cyan } from "@mui/material/colors";
import dayjs from 'dayjs';
import { DashboardApiCall, GetSatartAndEndDate } from '../../modual/CommanApiFunction';
import { useDispatch } from "react-redux";


export default function DashboardCard({ title, dataList = [], request = "" }) {
  const dispatch = useDispatch();
  const onChangeFilterHandler = (type) => {
    const { startDate, endDate } = GetSatartAndEndDate(type);
    DashboardApiCall(`?startDate=${startDate}&endDate=${endDate}`, dispatch, request)
  }
  let colorN = 0;
  return (
    <>
      <Paper sx={{ p: 3, mb: 3 }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
          <Typography variant="h6" component="h2" color="primary" >
            {title}
          </Typography>
          <Select size="small" defaultValue={"Today"} onChange={(e) => onChangeFilterHandler(e.target.value)}>
            <MenuItem value="Today">Today ({dayjs().format("DD-MM-YYYY")})</MenuItem>
            <MenuItem value="yesterday">YesterDay ({dayjs().add(-1, "day").format("DD-MM-YYYY")})</MenuItem>
            <MenuItem value="ThisWeek">This Week</MenuItem>
            <MenuItem value="LastWeek">Last Week</MenuItem>
            <MenuItem value="ThisMonth">{dayjs().format("MMMM")} Month</MenuItem>
            <MenuItem value="LastMonth">{dayjs().add(-1, "month").format("MMMM")} Month</MenuItem>
            <MenuItem value="ThisYear">Year {dayjs().format("YYYY")} </MenuItem>
            <MenuItem value="LastYear">Year {dayjs().add(-1, "year").format("YYYY")}</MenuItem>
          </Select>
        </div>
        <Divider />
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
            <Grid container spacing={3}>
              {dataList.map((data, i) => (
                <Grid
                  key={`db_Card_${i}`}
                  // sx={{ boxShadow: 3 }}
                  item
                  xs={6}
                  md={3}
                  lg={2}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <Card
                    style={{
                      backgroundColor:
                        i % 2 === 0
                          ? cyan[800 - colorN++ * 100]
                          : cyan[800 - colorN * 100],
                      // backgroundColor: grey[700],
                      padding: "6px 0px",
                      color: "#fff",
                      fontWeight: "bold",
                      height: "4.5rem",
                      borderRadius: 7,
                      textAlign: "center",
                      fontSize: "0.875rem",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3} sx={{ mt: 1 }}>
                        {data.icon}
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5">
                          {data.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: -2 }}>
                        <Typography variant="p">
                          {data.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

    </>
  );
}
