import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore, PowerOff } from "@mui/icons-material";
import { LogoutFunctionHandler } from "../Api/APICall";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Divider, List } from "@mui/material";

export function NavBarListItem({ menuList }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState({
    status: false,
    target: -1
  })
  function handleClick(index) {
    setOpen(
      {
        status: (!open.status),
        target: index
      })
  }
  const { data } = useSelector((state) => state.UserDataReducer);
  return (
    <>
      {menuList.map((item, i) => (
        <div key={`nav_item_${i}`}>
          {(item.subMenu === undefined) ?
            <ListItemButton key={`list_menu_${i}`} onClick={() => navigate(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton> :
            <div onClick={() => handleClick(i)} key={`list-sub${i}`}>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
                {(open.target === i && open.status) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={(open.target === i) ? open.status : false} timeout="auto" unmountOnExit key={`sub-menu${i}`}>
                <Divider />
                {
                  item.subMenu.map(sub => (
                    <List component="div" disablePadding key={`sub-menu${i}`}>
                      <ListItemButton onClick={() => navigate(sub.path)}>
                        <ListItemIcon>{sub.icon}</ListItemIcon>
                        <ListItemText inset primary={sub.name} />
                      </ListItemButton>
                    </List>
                  ))
                }
              </Collapse>
            </div>
          }
        </div>
      ))}

      <ListItemButton onClick={() => LogoutFunctionHandler(dispatch, data)}>
        <ListItemIcon>
          <PowerOff />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </>
  );
}
