import React, { useEffect, useState } from 'react'
import LineGraph from '../../../components/graphs/LineGraph'
import { GetSatartAndEndDate, GraphApiCall } from '../../CommanApiFunction';

export default function DashboardLineGraph() {
    const [GraphViewData, setGraphViewData] = useState({ hindi: [], english: [] })
    const onChangeFilterHandler = (type) => {
        const { startDate, endDate } = GetSatartAndEndDate(type);
        GraphApiCall(`/line?startDate=${startDate}&endDate=${endDate}`, setGraphViewData, "Line")
    }
    useEffect(() => {
        onChangeFilterHandler("Today");
    }, [])
    return (
        <>
            <LineGraph
                data={GraphViewData}
                title={"BOT Response Trend"}
                onChangeFilterHandler={onChangeFilterHandler}
            />
        </>
    )
}
