import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Auths from "./auth";
import MainPage from "./modual";
import LoginPage from "./modual/LoginPage";
import NotFoundPage from "./modual/NotFoundPage";
import { createTheme, ThemeProvider } from '@mui/material';

const App = () => {
  const theme = createTheme();


  const { data, isLogin } = useSelector((state) => state.UserDataReducer);
  var curentRol = data.userRole;
  var giveAccess = [];

  if (data?.userRole !== undefined) {
    const curentRolData = Auths.filter(
      (auth) => auth.role === curentRol.toUpperCase()
    )[0];
    giveAccess = Auths.filter((auth) =>
      curentRolData.access.includes(auth.key)
    );
    curentRol = curentRolData.role;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          {isLogin ? (
            <Route path="/" element={<MainPage />}>
              {giveAccess.map((list, i) => (
                <Route
                  index
                  path={`${list.role.toLowerCase()}/*`}
                  element={list.filePath}
                  key={i}
                />
              ))}
              <Route
                exact
                path="/"
                element={<Navigate to={curentRol.toLowerCase()} />}
              />
            </Route>
          ) : (
            <>
              <Route path="/" element={<LoginPage />} />
              {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            </>
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ThemeProvider>
    </>

  );
};

export default App;
