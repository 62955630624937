import { Book } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
const AdminMenuList = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: <DashboardIcon />,
  }, {
    name: "BOT Performance",
    path: "/admin/performance",
    icon: <Book />,
  },
  //  {
  //   name: "Tickets Reports",
  //   path: "ticket-report",
  //   icon: <SummarizeIcon />,
  //   // subMenu: [
  //   //   { name: "Tickets", path: "ticket-report", icon: <DashboardIcon />, },
  //   //   { name: "Ticket History", path: "interaction-report", icon: <DashboardIcon />, },
  //   //   { name: "Ticket Interaction", path: "history-report", icon: <DashboardIcon />, }
  //   // ],
  // },
];

export default AdminMenuList;
