import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import APICall, { explodeDataWithRowTable } from '../components/Api/APICall';
import { setDashboardTicketsCount } from '../reducers/actions/action';
const CommanApiGETFunction = (url, setOutputList) => {
  APICall(url, "GET")
    .then((data) => {
      setOutputList(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

const DashboardApiCall = (url, dispatch, request = "") => {
  APICall(`/dashboard${url}`).then(data => {
    if (data.status) {
      let tmp = {
        HindiCancelledTickets: 0,
        EnglishCancelledTickets: 0,
        BOTResponded: 0,
        BOTNotResponded: 0,
        TotalCancelledTickets: 0,
        TotalAPICalls: 0,
        APIResultsSuccess: 0,
        APIResultsFail: 0,
        TotalBOTSuccessPercent: 0,
        TotalAPISuccessPercent: 0,
      }
      if (data.message.length > 0) {
        tmp = data.message[0];
        dispatch(setDashboardTicketsCount(tmp))
      } else {
        toast.error("Network is unavailable");
      }
    }
  }).catch(() => {
    toast.error("Opps ! Sumething want wrong");
  })
};


// this function convert total ticket to today time duration
const callTypeData = (data, callType) => {

  let tempCallType = "";
  if (callType === "hindi") {
    tempCallType = data.filter((item) => item.Language === "hindi");
  } else if (callType === "english") {
    tempCallType = data.filter((item) => item.Language === "english");
  } else {
    tempCallType = []
  }
  const tempHoursData = [];

  for (let i = 0; i < 24; i++) {
    const temphours = tempCallType.filter(
      (item) => parseInt(dayjs(`${item.RDateTime}`).format('HH')) === i
    );
    const t = { hours: i, data: temphours.length };
    tempHoursData.push(t);
  }
  return tempHoursData;
};

const GraphApiCall = (url, setData, request = "") => {
  APICall(`/graph${url}`).then(data => {
    if (data.status) {
      if (request === "bar") {
        const GrphLable = Object.keys(data.message[0]);
        const Grphdata = Object.values(data.message[0]);
        setData({
          lable: GrphLable,
          data: Grphdata
        })
      } else {
        const completeCallData = {
          hindi: callTypeData(data.message, "hindi"),
          english: callTypeData(data.message, "english"),
        };
        setData(completeCallData);
      }
    }
  }).catch(() => {
    toast.error("Opps ! Sumething want wrong");
  })
};


const GetSatartAndEndDate = (type) => {
  const data = {
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  }
  switch (type) {
    case "yesterday":
      const thisWeek = dayjs().add(-1, "day");
      data.startDate = dayjs(thisWeek).format("YYYY-MM-DD");
      data.endDate = dayjs(thisWeek).format("YYYY-MM-DD");

      break;
    case "ThisWeek":
      const yesterDay = dayjs().startOf("week");
      data.startDate = dayjs(yesterDay).format("YYYY-MM-DD");
      data.endDate = dayjs(yesterDay).endOf("week").format("YYYY-MM-DD");

      break;
    case "LastWeek":
      const lastWeek = dayjs().startOf("week");
      data.startDate = dayjs(lastWeek).add(-7, "day").format("YYYY-MM-DD");
      data.endDate = dayjs(lastWeek).add(-1, "day").format("YYYY-MM-DD");

      break;
    case "ThisMonth":
      const thisMonth = dayjs().startOf("month");
      data.startDate = dayjs(thisMonth).format("YYYY-MM-DD");
      data.endDate = dayjs(thisMonth).endOf("month").format("YYYY-MM-DD");
      break;
    case "LastMonth":
      const lastMonth = dayjs().startOf("month");
      data.startDate = dayjs(lastMonth).add(-1, "month").format("YYYY-MM-DD");
      data.endDate = dayjs(lastMonth).add(-1, "day").format("YYYY-MM-DD");
      break;
    case "ThisYear":
      const thisYear = dayjs().startOf("year");
      data.startDate = dayjs(thisYear).format("YYYY-MM-DD");
      data.endDate = dayjs().endOf("year").format("YYYY-MM-DD");
      break;
    case "LastYear":
      const lastYear = dayjs().startOf("year");
      data.startDate = dayjs(lastYear).add(-1, "year").format("YYYY-MM-DD");
      data.endDate = dayjs(lastYear).add(-1, "day").format("YYYY-MM-DD");
      break;

    default:
      break;
  }
  return data;
}

function convortDateStingToDateTime(datetime) {
  const [dateValues, timeValues] = datetime.split(' ');
  const [month, day, year] = dateValues.split('-');
  const [hours, minutes, seconds] = timeValues.split(':');
  const date = new Date(year, month - 1, day, hours, minutes, seconds);
  return date;
}

const getReportApiCall = (setData, option = "", explodeData = true) => {
  APICall(`/report/${option}`, "GET")
    .then((data) => {
      if (data.status) {
        if (Array.isArray(data.message)) {
          if (explodeData) {
            explodeDataWithRowTable(data.message, setData);
          } else {
            setData(data.message);
          }
        } else {
          toast.warn("No Data Found");
          explodeDataWithRowTable([], setData);
        }
      } else {
        toast.error(data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export default CommanApiGETFunction;
export {
  GetSatartAndEndDate,
  convortDateStingToDateTime,
  DashboardApiCall,
  getReportApiCall,
  GraphApiCall,
}

