const TicketTableHeader =
    [
        {
            "field": "id",
            "headerName": "ID"
        }, {
            "field": "APICallID",
            "headerName": "APICallID",hide: true
        },
        {
            "field": "PhoneNumber",
            "headerName": "PhoneNumber",width: 220
        },
        
        {
            "field": "ERefundResponse1",
            "headerName": "ERefundResponse1", hide: true
        },
        {
            "field": "HRefundResponse1",
            "headerName": "HRefundResponse1", hide: true
        },
        {
            "field": "ERefundResponse2",
            "headerName": "ERefundResponse2", hide: true
        },
        {
            "field": "HRefundResponse2",
            "headerName": "HRefundResponse2", hide: true
        },
        {
            "field": "ERefundResponse3",
            "headerName": "ERefundResponse3", hide: true
        },
        {
            "field": "HRefundResponse3",
            "headerName": "HRefundResponse3", hide: true
        },
        {
            "field": "RDateTime",
            "headerName": "RDateTime", width: 220
        },
        {
            "field": "MP3Created",
            "headerName": "BOTResponse",width: 220
        },
        {
            "field": "MP3FileName",
            "headerName": "MP3FileName",width: 220,hide: true
        },
        {
            "field": "FileCreationDateTime",
            "headerName": "FileCreationDateTime",width: 220,hide: true
        },
        {
            "field": "RecordCalledStatus",
            "headerName": "RecordCalledStatus",hide: true
        },
        {
            "field": "CallResponse",
            "headerName": "CallResponse",hide: true
        },
        {
            "field": "RecordCallingDateTime",
            "headerName": "RecordCallingDateTime",hide: true
        },
        {
            "field": "Language",
            "headerName": "Language"
        },{
            "field": "Error",
            "headerName": "APIError",width: 350
        },
    ]


export default TicketTableHeader;