import React, { useEffect } from "react";
import Chart from "chart.js";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";
import { blue, green, orange, pink, red } from "@mui/material/colors";

export default function BarGraph({
  data = [],
  barLabels = [],
  title = "Overview",
  id = "bar-chart",
  onChangeFilterHandler
}) {
  useEffect(() => {
    let config = {
      type: "bar",
      data: {
        labels: barLabels,
        datasets: [
          {
            data,
            backgroundColor: [green[500],blue[400],pink[500],orange[300],red[800]],
            borderColor: [green[500],blue[400],pink[500],orange[300],red[800]],
            borderWidth: 2,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#000",
              },
              display: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                suggestedMax: 10,
                fontColor: "#000",
                precision: 0,
              },
              display: true,
            },
          ],
        },
      },
    };
    let ctx = document.getElementById(`${id}-bar-chart`).getContext("2d");
    window.myBar = new Chart(ctx, config);
  }, [data, barLabels,id]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7} md={7} lg={7}>
          <Typography variant="h6" component="h2" color="primary" >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Select size="small" defaultValue={"Today"} onChange={(e) => onChangeFilterHandler(e.target.value)}>
            <MenuItem value="Today">Today ({dayjs().format("DD-MM-YYYY")})</MenuItem>
            <MenuItem value="yesterday">YesterDay ({dayjs().add(-1, "day").format("DD-MM-YYYY")})</MenuItem>
            <MenuItem value="ThisWeek">This Week</MenuItem>
            <MenuItem value="LastWeek">Last Week</MenuItem>
            <MenuItem value="ThisMonth">{dayjs().format("MMMM")} Month</MenuItem>
            <MenuItem value="LastMonth">{dayjs().add(-1, "month").format("MMMM")} Month</MenuItem>
            <MenuItem value="ThisYear">Year {dayjs().format("YYYY")} </MenuItem>
            <MenuItem value="LastYear">Year {dayjs().add(-1, "year").format("YYYY")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <canvas id={`${id}-bar-chart`} style={{ maxHeight: "350px" }}></canvas>
    </>
  );
}
