import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, CssBaseline, darken, lighten, Paper, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';




// const columns = [
//   {
//     field: "firstName",
//     headerClassName: 'table-header-style',
//     headerName: "First name", hide: true
//   },
// ];

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
export default function MaterialTableComponent(props) {
    const calumnData = props.columns.map(item => ({ ...item, headerClassName: "table-header-style" }))

    const allProps = { ...props, columns: calumnData }

    return (
        <>
            <CssBaseline />
            <Paper>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Typography variant="h6" component="h2" color="primary">
                        {props.title}
                    </Typography>
                    {props.AddButton}
                </div>
                <Box
                    sx={{
                        height: props.TableHeight || 600,
                        width: '100%',
                        '& .table-header-style': {
                            backgroundColor: grey['300'],
                        },
                        '& .super-app-theme--Close': {
                            bgcolor: (theme) =>
                                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                            '&:hover': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
                            },
                        },
                        '& .super-app-theme--Pending': {
                            bgcolor: (theme) =>
                                getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
                            '&:hover': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.info.main,
                                        theme.palette.mode,
                                    ),
                            },
                        },
                        '& .super-app-theme--Open': {
                            bgcolor: (theme) =>
                                getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                            '&:hover': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
                            },
                        },
                    }}

                >
                    <DataGrid
                        {...allProps}
                        disableSelectionOnClick
                        components={(props.export === undefined) ? { Toolbar: GridToolbar } : {}}
                        componentsProps={{
                            toolbar: { showQuickFilter: true },
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
}
