import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../components/footer/Footer";
import { Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { encriptDataApi } from "../components/Api/APICall";
import { setUserLoginData } from "../reducers/actions/action";
import { toast, ToastContainer } from "react-toastify";
import { red } from "@mui/material/colors";
import userList from '../auth/userList';
import dayjs from "dayjs";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
  },
});

export default function LoginPage() {
  document.querySelector("body").style.backgroundImage =
    `url(${require('../assets/loginBackground.jpg')})`;
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      userId: data.get("LoginId"),
      Password: data.get("password"),
    };


    const userAvilable = userList.filter(user => user.LoginId === formData.userId && user.password === formData.Password)
    if (userAvilable.length > 0) {

      userAvilable[0].expiryDate = dayjs().endOf("day")
      const encriptData = encriptDataApi(JSON.stringify(userAvilable[0]));
      toast.success("Login successful");
      dispatch(setUserLoginData(userAvilable[0]));
      localStorage.setItem("isLogin", encriptData);
      window.location.reload();
    }
    else {
      toast.error("User Not found");
    }

    //
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Container component="main" maxWidth="xs" sx={{ backgroundColor: "#fff", borderRadius: '5%', pb: 1 }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: "40%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={require('../assets/logo.png')} alt="logo" style={{ width: "50%" }} />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Login Id"
              name="LoginId"
              autoComplete="false"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: red[400] }}
            >
              Sign In
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }}
          elevation={3}
        >
          <Footer sx={{ pt: 4 }} />
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
