import { Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { getReportApiCall } from "../../CommanApiFunction";
import { toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MaterialTableComponent from '../../../components/Table/material/MaterialTableComponent';
import TicketTableHeader from '../../../components/data/TablesHeaders/TicketTablesHeader';

export default function TicketsReportComponents() {
  const [TicketDataList, setTicketDataList] = useState({
    row: [],
    dataWithRows: [],
    onlyData: [],
  })
  const [FilterInput, setFilterInput] = useState({
    BotLanguage: "0",
    botResponce: "0",
    startDate: dayjs().format("MM/DD/YYYY"),
    endDate: dayjs().format("MM/DD/YYYY"),
  });

  const InputOnChangeHandler = (e) => {
    const { value, name } = e.target;
    setFilterInput((v) => ({ ...v, [name]: value }));
  };
  const showReportHandoler = () => {
    if (
      FilterInput.startDate === "" ||
      FilterInput.endDate === ""
    ) {
      toast.error("Please select all filter")
    } else {
      const option = `tickets?BotLanguage=${FilterInput.BotLanguage}&startDate=${dayjs(FilterInput.startDate).format("YYYY-MM-DD")}&endDate=${dayjs(FilterInput.endDate).format("YYYY-MM-DD")}&botResponce=${FilterInput.botResponce}`;
      getReportApiCall(setTicketDataList, option)
    }
  }


  const calumnData = TicketDataList.dataWithRows.map((row) => ({
    ...row,
    FileCreationDateTime: dayjs(row.FileCreationDateTime).format("DD:MM:YYYY HH:mm:ss A"),
    RDateTime: dayjs(row.RDateTime).format("DD:MM:YYYY HH:mm:ss A"),
    RecordCallingDateTime: dayjs(row.RecordCallingDateTime).format("DD:MM:YYYY HH:mm:ss A")
  }))




  return (
    <>
      <Card>
        <Grid container spacing={1} sx={{ p: 2 }}>
          <Grid item sm={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Bot Language
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Bot Responce Language"
                name="BotLanguage"
                defaultValue={FilterInput.BotLanguage}
                onChange={InputOnChangeHandler}
              >
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="hindi">Hindi</MenuItem>
                <MenuItem value="englisg">English</MenuItem>

              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Bot Responce
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Bot Responce"
                name="botResponce"
                defaultValue={FilterInput.botResponce}
                onChange={InputOnChangeHandler}
              >
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>

              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/DD/YYYY"
                value={FilterInput.startDate}
                onChange={(newval) => setFilterInput((v) => ({ ...v, startDate: newval }))}
                id="startDate"
                name="startDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

          </Grid>
          <Grid item sm={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End Date"
                inputFormat="MM/DD/YYYY"
                value={FilterInput.endDate}
                onChange={(newval) => setFilterInput((v) => ({ ...v, endDate: newval }))}
                id="endDate"
                name="endDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

          </Grid>
          <Grid item sm={2}>
            <Button
              onClick={showReportHandoler}
              sx={{ p: 1, m: 1 }}
              type="button"
              variant="contained"
              color="secondary"
            >
              Search Report
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Divider sx={{ p: 1 }} />
      {/* {JSON.stringify(TicketDataList.row)} */}
      {
        TicketDataList.row.length > 0 ?
          <MaterialTableComponent
            TableHeight={620}
            title={"BOT Performance Report"}
            rows={calumnData}
            columns={TicketTableHeader}
            getRowClassName={(params) => `super-app-theme--${params.row.TicketStatus}`}
          /> : ""}
      <Divider sx={{ mb: 2 }} />
    </>
  )
}
