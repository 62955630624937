import React from "react";
import Chart from "chart.js";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";

export default function LineGraph({ data = {}, onChangeFilterHandler, title }) {
  //
  const { hindi = [], english = [] } = data;

  React.useEffect(() => {
    var config = {
      type: "line",
      data: {
        labels: [
          "12-1AM",
          "1-2AM",
          "2-3AM",
          "3-4AM",
          "4-5AM",
          "5-6AM",
          "6-7AM",
          "7-8AM",
          "8-9AM",
          "9-10AM",
          "10-11AM",
          "11-12PM",
          "12-1PM",
          "1-2PM",
          "2-3PM",
          "3-4PM",
          "4-5PM",
          "5-6PM",
          "6-7PM",
          "7-8PM",
          "8-9PM",
          "9-10PM",
          "10-11PM",
          "11-12AM",
        ],
        datasets: [
          {
            label: "English Response",
            backgroundColor: "#000",
            borderColor: "rgba(75, 192, 192, 1)",
            data: hindi.map((d) => d.data),
            fill: false,
          }, {
            label: "Hindi Response",
            backgroundColor: "#000",
            borderColor: "rgba(255, 0, 255, 1)",
            data: english.map((d) => d.data),
            fill: false,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          labels: {
            fontColor: "#000",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#000",
              },
              display: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                suggestedMax: 10,
                fontColor: "#000",
                precision: 0,
              },
              display: true,
            },
          ],
        },
      },
    };

    var ctx = document.getElementById("line-chart").getContext("2d");
    window.myLine = new Chart(ctx, config);
  }, [hindi, english]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7} md={7} lg={7}>
          <Typography variant="h6" component="h2" color="primary" >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Select size="small" defaultValue={"Today"} onChange={(e) => onChangeFilterHandler(e.target.value)}>
            <MenuItem value="Today">Today ({dayjs().format("DD-MM-YYYY")})</MenuItem>
            <MenuItem value="yesterday">YesterDay ({dayjs().add(-1, "day").format("DD-MM-YYYY")})</MenuItem>
            <MenuItem value="ThisWeek">This Week</MenuItem>
            <MenuItem value="LastWeek">Last Week</MenuItem>
            <MenuItem value="ThisMonth">{dayjs().format("MMMM")} Month</MenuItem>
            <MenuItem value="LastMonth">{dayjs().add(-1, "month").format("MMMM")} Month</MenuItem>
            <MenuItem value="ThisYear">Year {dayjs().format("YYYY")} </MenuItem>
            <MenuItem value="LastYear">Year {dayjs().add(-1, "year").format("YYYY")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <canvas id="line-chart" style={{ maxHeight: "350px" }}></canvas>
    </>
  );
}
