import { combineReducers } from "redux";
import UserDataReducer from "./reducer/UserDataReducer";
import DashboardPerformanceReducer from './reducer/DashboardPerformanceReducer';
const rootReducer = combineReducers({
  UserDataReducer,
  DashboardPerformanceReducer,

});

export default rootReducer;
